










































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { shoppingMallArrival, consumeAbility } from '@/utils/echarts/echarts';
import * as Services from '@/services/api';
@Component({})
export default class NaturalProperty extends Vue {
  $dayjs: any;
  tips = true;
  shoppingMallArrivalOption = {};
  excelArr: any = [];
  consumeAbilityOption = {};
  educationOption = {};
  childrenOption = {};
  workOption = {};
  genderOption = {};
  marriageOption = {};
  ageshow = true;
  educationshow = true;
  childrenshow = true;
  gendershow = true;
  workshow = true;
  marriageshow = true;
  ageList = [
    '17岁以下',
    '18-24',
    '25-30',
    '31-35',
    '36-40',
    '41-45',
    '46-60',
    '61岁及以上',
  ];
  colorList = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
  colorList2 = ['#0062ff', '#ee5c3d'];
  educationList = ['博士', '硕士', '本科', '大专', '高中', '初中', '小学'];
  childrenList = ['孕婴', '1-5岁', '6-12岁', '13-18岁'];
  genderList = ['男性', '女性'];
  workList = ['中学生', '大学生', '上班族', '自由职业', '退休'];
  marriageList = ['已婚', '未婚'];
  typeList = [101, 102, 103, 104, 105, 106];
  $beaconAction: any;
  @Watch('$store.state.mall.monthDate', { immediate: false, deep: true })
  monthDateChange(val: string): void {
    this.initData(
      this.$store.state.mall.mallId,
      this.$dayjs(val).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
    );
  }
  @Watch('$store.state.mall.mallId', { immediate: false, deep: true })
  mallIdChange(val: number): void {
    this.initData(
      val,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
    );
  }

  @Watch('$store.state.mall.competedModelObj.nMallId', {
    immediate: false,
    deep: true,
  })
  competeMallIdChange(val: string): void {
    this.initData(
      this.$store.state.mall.mallId,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      val,
    );
  }
  @Watch('$store.state.global.downloadStatus')
  changeExport(val: boolean): void {
    const datas = [];
    if (val === true) {
      datas.push({
        sheetData: this.excelArr,
        sheetName: '客流画像-自然属性',
        sheetHeader: [
          '日期',
          '分类',
          '画像标签',
          '占比(%)',
        ],
        sheetFilter: ['date', 'title', 'type', 'percent'],
        columnWidths: [8, 8, 8, 8, 8],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  async mounted(): Promise<void> {
    this.initData(
      this.$store.state.mall.mallId,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
    );
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  async initData(
    mallid: number,
    date: string,
    competeId: string,
  ): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.excelArr = [];
    const mallId = 'mall_id';
    const competeMallId = 'compete_mall_id';
    const jType = 'j_type';
    Promise.all(this.typeList.map(async (i: number) => await Services.getGridPerAgePortraitByCompetemallIdSvc({
      [mallId]: mallid,
      [competeMallId]: competeId,
      date,
      type: i,
      duration: 'xm',
      [jType]: '1',
    }))).then((res) => {
      res.forEach((j, index) => {
        if (j.status === 0) {
          if (index === 0) {
            this.genderOption = this.pieCardDisplay(
              j.data,
              this.genderList,
              '性别',
              this.colorList2,
            );
            this.gendershow = false;
          }
          if (index === 1) {
            this.shoppingMallArrivalOption = this.lineCardDisplay(
              j.data,
              this.ageList,
              '年龄',
            );
            this.ageshow = false;
          }
          if (index === 2) {
            this.educationOption = this.lineCardDisplay(
              j.data,
              this.educationList,
              '学历',
            );
            this.educationshow = false;
          }
          if (index === 3) {
            // this.childrenOption = this.pieCardDisplay(
            //   j.data,
            //   this.childrenList,
            //   '子女状态',
            //   this.colorList,
            // );
            // this.childrenshow = false;
          }
          if (index === 4) {
            this.marriageOption = this.pieCardDisplay(
              j.data,
              this.marriageList,
              '婚姻状态',
              this.colorList,
            );
            this.marriageshow = false;
          }
          if (index === 5) {
            this.workOption = this.pieCardDisplay(
              j.data,
              this.workList,
              '人生阶段',
              this.colorList,
            );
            this.workshow = false;
          }
        }
      });
      this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
    });
  }
  pieCardDisplay(
    data: any,
    list: any,
    title: string,
    colorList: string[],
  ): any {
    const consumeValue = Object.values(data);
    consumeValue.forEach((i, ind) => {
      Object.assign(i, {
        title,
        type: list[ind],
        date: this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      });
    });
    this.excelArr.push(...consumeValue);
    return consumeAbility(
      list,
      consumeValue.map(i => (i as any).percent),
      title,
      '',
      colorList,
    );
  }
  lineCardDisplay(data: any, list: any, title: string): any {
    const mallValue = Object.values(data);
    mallValue.forEach((i, ind) => {
      Object.assign(i, {
        title,
        type: list[ind],
        date: this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      });
    });
    this.excelArr.push(...mallValue);
    return shoppingMallArrival(
      list,
      mallValue.map(i => (i as any).percent),
      title,
    );
  }
}
