


































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { shoppingMallArrival } from '@/utils/echarts/echarts';
import * as Services from '@/services/api';
@Component({})
export default class extends Vue {
  $dayjs: any;
  excelArr: any=[];
  tips = true;
  carOption = {};
  shoppingOption = {};
  leisureOption = {};
  sportOption = {};
  restaurantOption = {};
  restaurantList = {
    10: '中餐厅',
    11: '农家菜',
    12: '官府菜',
    13: '家常菜',
    14: '私家菜',
    15: '烧烤',
    16: '火锅',
    17: '海鲜',
    18: '特色中餐',
    19: '素食',
    20: '小吃快餐',
    21: '清真',
    22: '日韩菜',
    23: '东南亚菜',
    24: '西餐',
    25: '自助餐',
    26: '面包甜点',
    27: '茶餐厅',
    28: '冷饮店',
    99: '其它美食',
  };
  shoppingList = {
    10: '综合商场',
    13: '数码家电',
    15: '家具家居建材',
    19: '体育户外',
    21: '图书音像',
    23: '母婴儿童',
    24: '珠宝饰品',
    26: '商业步行街',
  };
  leisureList = {
    10: '洗浴推拿足疗',
    11: 'KTV',
    12: '酒吧',
    13: '咖啡厅',
    14: '夜总会',
    16: '电影院',
    17: '剧场音乐厅',
    18: '度假疗养',
    1910: '游乐场',
    1911: '拓展培训',
    1912: '垂钓园',
    1913: '采摘园',
    1914: '露营地',
    1915: '水上活动中心',
    21: '网吧',
    1999: '其它户外活动',
  };
  sportList = {
    10: '健身中心',
    11: '游泳馆',
    13: '瑜伽',
    14: '羽毛球馆',
    18: '足球场',
    21: '马术',
    23: '高尔夫场',
    25: '台球馆',
    26: '滑雪',
    27: '溜冰',
    29: '舞蹈',
    30: '综合体育场馆',
  };
  carList = {
    12: '汽车销售',
    13: '汽车维修',
    15: '驾校',
    16: '汽车租赁',
    22: '二手车交易市场',
    24: '车辆管理机构',
  };
  carshow = true;
  shoppingshow = true;
  leisureshow = true;
  sportshow = true;
  restaurantshow = true;
  typeList = [501, 502, 503, 504, 505];
  $beaconAction: any;
  @Watch('$store.state.mall.monthDate', { immediate: false, deep: true })
  monthDateChange(val: string): void {
    this.initData(
      this.$dayjs(val).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
      this.$store.state.mall.mallId,
    );
  }
  @Watch('$store.state.mall.mallId', { immediate: false, deep: true })
  mallIdChange(val: number): void {
    this.initData(
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
      val,
    );
  }
  @Watch('$store.state.mall.competedModelObj.nMallId', { immediate: false, deep: true })
  competeMallIdChange(val: string): void {
    this.initData(
      this.$store.state.mall.mallId,
      val,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
    );
  }
  @Watch('$store.state.global.downloadStatus')
  changeExport(val: boolean): void {
    const datas = [];
    if (val === true) {
      datas.push({
        sheetData: this.excelArr,
        sheetName: '客流画像-到访偏好',
        sheetHeader: [
          '日期',
          '分类',
          '画像标签',
          '占比(%)',
        ],
        sheetFilter: ['date', 'title', 'type', 'percent'],
        columnWidths: [8, 8, 8, 8, 8],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  mounted(): void {
    this.initData(
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
      this.$store.state.mall.mallId,
    );
  }

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  async initData(
    date: string,
    competeId: string,
    mallid: number,
  ): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.carshow = true;
    this.shoppingshow = true;
    this.leisureshow = true;
    this.sportshow = true;
    this.restaurantshow = true;
    const mallId = 'mall_id';
    const competeMallId = 'compete_mall_id';
    const jType = 'j_type';
    this.excelArr = [];
    Promise.all(this.typeList.map(async (i: number) => await Services.getGridPerAgePortraitByCompetemallIdSvc({
      [mallId]: mallid,
      [competeMallId]: competeId,
      date,
      type: i,
      duration: 'xm',
      [jType]: '1',
    }))).then((res) => {
      res.forEach((i, index) => {
        if (i.status === 0) {
          if (index === 0) {
            // 餐饮
            this.restaurantOption = this.cardDataDisplay(
              i.data,
              this.restaurantList,
              '餐饮',
            );
            this.restaurantshow = false;
          }
          if (index === 1) {
            // 购物
            this.shoppingOption = this.cardDataDisplay(
              i.data,
              this.shoppingList,
              '购物',
            );
            this.shoppingshow = false;
          }
          if (index === 2) {
            // 休闲娱乐
            this.leisureOption = this.cardDataDisplay(
              i.data,
              this.leisureList,
              '休闲娱乐',
            );
            this.leisureshow = false;
          }
          if (index === 3) {
            // 运动健身
            this.sportOption = this.cardDataDisplay(
              i.data,
              this.sportList,
              '运动健身',
            );
            this.sportshow = false;
          }
          if (index === 4) {
            // 汽车
            this.carOption = this.cardDataDisplay(i.data, this.carList, '汽车');
            this.carshow = false;
          }
        }
      });
      this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  cardDataDisplay(data: any, list: any, title: string): any {
    const newList = Object.values(data).sort((a: any, b: any) => b.percent - a.percent);
    const serviceValue = newList
      .map((j: any) => {
        // eslint-disable-next-line no-param-reassign
        (j as any).title = list[Number(j.property)];
        // eslint-disable-next-line no-param-reassign
        (j as any).type = title;
        // eslint-disable-next-line no-param-reassign
        j.date = this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM');
        return j;
      });
    this.excelArr.push(...serviceValue);
    return shoppingMallArrival(
      serviceValue.map(i => i.title),
      serviceValue.map(i => i.percent),
      title,
    );
  }
}
