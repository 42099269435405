

































import { Vue, Component, Prop } from 'vue-property-decorator';
import naturalProperty from './naturalProperty/index.vue';
import economyProperty from './economyProperty/index.vue';
import arrival from './arrival/index.vue';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
@Component({
  components: {
    economyProperty,
    naturalProperty,
    arrival,
    tdesignFooter,
  },
})
export default class extends Vue {
  list = [
    {
      name: '自然属性',
      buriedPoint: 'competition_image_basic_click',
    },
    {
      name: '财富属性',
      buriedPoint: 'competition_image_wealth_click',
    },
    {
      name: '到访偏好',
      buriedPoint: 'competition_image_visit_click',
    },
  ];
  clickValue: any = 0;
  @Prop({
    type: Array,
    required: false,
  })
  data!: [];
  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  checkMenulist(val: number): void {
    this.clickValue = val;
    sessionStorage.setItem('clickValue', val.toString());
    const item = this.list[val];
    this.buriedPointHandler(item.buriedPoint);
  }
}
