





















































































































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { shoppingMallArrival, consumeAbility } from '@/utils/echarts/echarts';
import * as Services from '@/services/api';
@Component({})
export default class extends Vue {
  tips = true;
  $dayjs: any;
  failTips = '暂无数据';
  excelArr: any = [];
  consumeOption = {};
  carOption = {};
  restaurantOption = {};
  restaurantlevelOption = {};
  phonepriceOption = {};
  phoneOption = {};
  serviceOption = {};
  mallOption = {};
  communityOption = {};
  consumeshow = true;
  carshow = true;
  phoneshow = true;
  restaurantshow = true;
  restaurantlevelshow = true;
  phonepriceshow = true;
  serviceshow = true;
  mallshow = true;
  communityshow = true;
  consumeList = ['低', '较低', '中', '较高', '高'];
  carList = ['无车', '有车'];
  phoneList = ['苹果', '华为', '小米', 'VIVO', '魅族', 'OPPO', '三星', '其他'];
  restaurantList = ['1-2次', '3-4次', '5-6次', '7-8次', '9-10次', '10次以上'];
  serviceList = {
    10: 'DS',
    11: '奥迪',
    12: '北京现代',
    13: '北汽',
    14: '比亚迪',
    15: '标致',
    16: '别克',
    17: '宾利',
    18: '东风',
    19: '东风本田',
    20: '东风日产',
    21: '东南',
    22: '丰田',
    23: '风度日产',
    24: '福特',
    25: '广汽菲克',
    26: '广州本田',
    27: '汉腾',
    28: '吉利',
    29: '江淮汽车',
    30: '捷豹路虎',
    31: '金杯',
    32: '凯迪拉克',
    33: '铃木',
    34: '陆风',
    35: '名爵',
    36: '奇瑞汽车',
    37: '上海大众',
    38: '沃尔沃',
    39: '雪铁龙',
    40: '奔腾',
    41: '一汽大众',
    42: '一汽马自达',
    43: '悦达起亚',
    44: '长安',
    45: '长安马自达',
    46: '长城',
    47: '众泰',
    48: '宝马',
    49: '江铃',
    50: '荣威',
    51: '华泰',
    52: '日产',
    53: '林肯',
    54: '进口大众',
    55: '斯巴鲁',
    56: '英菲尼迪',
    57: '野马汽车',
    58: '上汽大通',
    59: '斯柯达',
    60: '雷克萨斯',
    61: '奔驰',
    62: '观致',
    63: '昌河',
    64: '玛莎拉蒂',
    65: '阿尔法罗密欧',
    66: '海马',
    67: '东风雷诺',
    68: '力帆',
    69: '华晨',
    70: '福田',
    71: '江淮',
    72: '猎豹',
    73: '天津一汽',
    74: '宝沃',
    75: '广汽本田',
    76: '比速',
    77: '法拉利',
    78: '传祺',
    79: '三菱',
    80: '哈弗',
    81: '保时捷',
    82: '兰博基尼',
    83: '阿斯顿马丁',
    84: '广汽',
    85: '依维柯',
    86: '劳斯莱斯',
    87: '迈凯伦',
    88: '讴歌',
    89: '金龙',
    90: '雪佛兰',
    91: '特斯拉',
    92: '腾势',
    93: '五菱',
    94: '现代',
    95: '红旗',
    96: '郑州日产',
  };
  restaurantlevelList = [
    '50以内',
    '51-100',
    '101-150',
    '151-200',
    '201-300',
    '301-500',
    '500以上',
  ];
  phonepriceList = [
    '1000以下',
    '1001-2000',
    '2001-3000',
    '3001-4000',
    '4001-5000',
    '5001-6000',
    '6000以上',
  ];
  mallList = ['1-2', '3-4', '5-6', '7-8', '9-10', '11以上'];
  communityList = [
    '2000-4999',
    '5000-7999',
    '8000-9999',
    '10000-19999',
    '20000-39999',
    '40000-59999',
    '60000-79999',
    '8000-99999',
    '100000以上',
  ];
  colorList = ['#0062ff', '#ee5c3d', '#40c8f5', '#74d857', '#846bce'];
  colorList2 = ['#0062ff', '#ee5c3d'];
  typeList = [107, 108, 109, 110, 111, 112, 115, 116];
  $beaconAction: any;
  @Watch('$store.state.mall.monthDate', { immediate: false, deep: true })
  monthDateChange(val: string): void {
    this.initData(
      this.$dayjs(val).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
      this.$store.state.mall.mallId,
    );
  }
  @Watch('$store.state.mall.mallId', { immediate: false, deep: true })
  mallIdChange(val: number): void {
    this.initData(
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
      val,
    );
  }
  @Watch('$store.state.mall.competedModelObj.nMallId', {
    immediate: false,
    deep: true,
  })

  buriedPointHandler(buriedPoint: string): void{
    this.$beaconAction.onUserAction(buriedPoint, {});
  }

  competeMallIdChange(val: string): void {
    this.initData(
      this.$store.state.mall.mallId,
      val,
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
    );
  }
  @Watch('$store.state.global.downloadStatus')
  changeExport(val: boolean): void {
    const datas = [];
    if (val === true) {
      datas.push({
        sheetData: this.excelArr,
        sheetName: '客流画像-财富属性',
        sheetHeader: [
          '日期',
          '分类',
          '画像标签',
          '占比(%)',
        ],
        sheetFilter: ['date', 'title', 'type', 'percent'],
        columnWidths: [8, 8, 8, 8, 8],
      });
      this.$root.$data.event.$emit('getDataS', datas);
    }
  }
  async mounted(): Promise<void> {
    this.initData(
      this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      this.$store.state.mall.competedModelObj.nMallId,
      this.$store.state.mall.mallId,
    );
  }
  async initData(
    date: string,
    competeId: string,
    mallid: number,
  ): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.consumeshow = true;
    this.carshow = true;
    this.phoneshow = true;
    this.restaurantshow = true;
    this.restaurantlevelshow = true;
    this.phonepriceshow = true;
    this.serviceshow = true;
    this.mallshow = true;
    this.communityshow = true;
    const mallId = 'mall_id';
    const competeMallId = 'compete_mall_id';
    const jType = 'j_type';
    Promise.all(this.typeList.map(async (i: number) => await Services.getGridPerAgePortraitByCompetemallIdSvc({
      [mallId]: mallid,
      [competeMallId]: competeId,
      date,
      type: i,
      duration: 'xm',
      [jType]: i === 116 ? '2' : '1',
    }))).then((res) => {
      res.forEach((i, index) => {
        if (i.status === 0) {
          if (index === 0) {
            // 消费水平
            this.consumeOption = this.pieCardDisplay(
              i.data,
              this.consumeList,
              '消费水平',
              this.colorList,
            );
            this.consumeshow = false;
          }
          if (index === 1) {
            // 商场到店频次
            this.mallOption = this.lineCardDisplay(
              i.data,
              this.mallList,
              '商场到店频次',
            );
            this.mallshow = false;
          }
          if (index === 2) {
            // 餐饮消费频次
            this.restaurantOption = this.lineCardDisplay(
              i.data,
              this.restaurantList,
              '餐饮消费频次',
            );
            this.restaurantshow = false;
          }
          if (index === 3) {
            // 餐饮消费水平
            this.restaurantlevelOption = this.lineCardDisplay(
              i.data,
              this.restaurantlevelList,
              '餐饮消费水平',
            );

            this.restaurantlevelshow = false;
          }
          if (index === 4) {
            // 居住社区房价
            this.communityOption = this.lineCardDisplay(
              i.data,
              this.communityList,
              '居住社区房价',
            );
            this.communityshow = false;
          }
          if (index === 5) {
            // 是否有车
            this.carOption = this.pieCardDisplay(
              i.data,
              this.carList,
              '是否有车',
              this.colorList2,
            );

            this.carshow = false;
          }
          if (index === 6) {
            // 手机品牌
            this.phoneOption = this.lineCardDisplay(
              i.data,
              this.phoneList,
              '手机品牌',
            );
            this.phoneshow = false;
          }
          if (index === 7) {
            // 手机价格
            this.phonepriceOption = this.lineCardDisplay(
              i.data,
              this.phonepriceList,
              '手机价格',
            );
            this.phonepriceshow = false;
          }
        }
      });
    });
    const orderBy = 'order_by';
    const topStart = 'top_start';
    const topEnd = 'top_end';
    const res = await Services.getGridPerAgePortraitCompetitionInterceptSSvc({
      [mallId]: mallid,
      [competeMallId]: competeId,
      date,
      type: 114,
      duration: 'xm',
      [orderBy]: 'num',
      [topStart]: 0,
      [topEnd]: 10,
    });
    if (Number(res.status) === 0) {
      const list = Object.values(res.data).sort((a: any, b: any) => b.percent - a.percent);
      const serviceValue = list.map((j: any) => {
        // eslint-disable-next-line no-param-reassign
        j.type = (this.serviceList as any)[Number(j.property)];
        // eslint-disable-next-line no-param-reassign
        j.title = '到访4s店品牌';
        // eslint-disable-next-line no-param-reassign
        j.date = this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM');
        return j;
      });
      this.excelArr.push(...serviceValue);
      this.serviceOption = shoppingMallArrival(
        serviceValue.map(i => (i as any).type),
        serviceValue.map(i => (i as any).percent),
        '到访4s店品牌',
      );
      this.serviceshow = false;
    }
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }
  pieCardDisplay(
    data: any,
    list: any,
    title: string,
    colorList: string[],
  ): any {
    const consumeValue = Object.values(data);
    consumeValue.forEach((i, ind) => {
      Object.assign(i, {
        title,
        type: list[ind],
        date: this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      });
    });
    this.excelArr.push(...consumeValue);
    return consumeAbility(
      list,
      consumeValue.map(i => (i as any).percent),
      title,
      '',
      colorList,
    );
  }
  lineCardDisplay(data: any, list: any, title: string): any {
    const mallValue = Object.values(data);
    mallValue.forEach((i, ind) => {
      Object.assign(i, {
        title,
        type: list[ind],
        date: this.$dayjs(this.$store.state.mall.monthDate).format('YYYY-MM'),
      });
    });
    this.excelArr.push(...mallValue);
    return shoppingMallArrival(
      list,
      mallValue.map(i => (i as any).percent),
      title,
    );
  }
}
